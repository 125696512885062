import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetPeopleLists, PeopleListFilterInput } from '@src/_gql';
import { ListCard } from '@components/ListCard';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { MMMDDYYYY } from '@config/date-formats';
import { PeopleListSharedBadge } from './PeopleListSharedBadge';
import { getUserNameLabel } from '@helpers/userName';
import {
  DisplayOptions,
  ListCardOptions,
  ShareObject
} from '@domains/Lists/components/ListCardOptions';
import { ROOT } from '@config/routes';
import { BluebellShareLevel, PeopleListState } from '@gql';
import { LIST_WRITER } from '@config/privileges';
import { PrivilegeEnforcementWrapper } from '@domains/Auth';
import { FavoriteListStar } from './FavoriteListStar';
import { ListStatusBadge } from '../ListStatusBadge';
import { isStableListState } from '../helpers/isStableListState';
import { useCurrentUserContext } from '@contexts/CurrentUser';
import { ArrayItem } from '@src/types';

type PeopleList = NonNullable<
  NonNullable<ArrayItem<NonNullable<GetPeopleLists['peopleLists']['edges']>>>['node']
>;

export type PeopleListNodeProps = {
  node: Pick<
    PeopleList,
    | 'id'
    | 'name'
    | 'state'
    | 'createdAt'
    | 'creator'
    | 'mutability'
    | 'orgShareLevel'
    | 'shareRules'
    | 'nodeUserPreferences'
  >;
  folderId?: string;
  refetch?: () => void;
  onFolderDelete?: () => void;
  appliedFilters?: Partial<PeopleListFilterInput>;
};

export const PeopleListNode: React.FC<PeopleListNodeProps> = ({
  node,
  folderId,
  refetch = () => null,
  onFolderDelete,
  appliedFilters
}) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUserContext();

  const isShared = node.orgShareLevel !== BluebellShareLevel.NONE || !!node.shareRules?.length;
  const shareObject: ShareObject = {
    id: node.id,
    name: node.name,
    orgShareLevel: node.orgShareLevel,
    shareRules: node.shareRules ? node.shareRules : []
  };

  const listCardDisplayOptions: DisplayOptions[] = ['edit', 'favorite', 'folder', 'clone'];
  if (isStableListState(node.state) || node.state === PeopleListState.pending) {
    listCardDisplayOptions.push('archive');
  }

  if (currentUser.user?.id === node.creator.id) {
    listCardDisplayOptions.push('share');
  }

  const favoriteFilterApplied = appliedFilters?.favorite || false;

  return (
    <ListCard>
      <div className="tw-w-full tw-flex tw-justify-between tw-items-center tw-py-4 tw-px-6">
        <div className="tw-flex tw-items-center">
          <div className="tw-text-xl">
            <div className="tw-flex">
              {node.nodeUserPreferences?.favorite ? (
                <FavoriteListStar listId={node.id} isFavorite={node.nodeUserPreferences.favorite} />
              ) : null}
              <Link to={folderId ? `${ROOT.LISTS.BASE}/${node.id}` : `${node.id}`}>
                <h3 className="tw-text-nrs-blue hover:tw-text-nrs-blue-dark">{node.name}</h3>
              </Link>
            </div>
            <div className="tw-my-2 tw-flex tw-text-sm tw-text-gray-500 tw-items-center">
              <div className="tw-mr-1">{t('lists.pages.list.status')}</div>
              <ListStatusBadge status={node.state} />
            </div>
            <p className="tw-text-xs tw-text-gray-500">
              {t('lists.pages.list.createdAtBy', {
                date: dayjs(node.createdAt).format(MMMDDYYYY),
                creator: getUserNameLabel(node.creator)
              })}
            </p>
          </div>
        </div>

        <div className="tw-flex tw-flex-row tw-items-center tw-mr-2">
          {isShared ? (
            <div className="tw-mr-2">
              <PeopleListSharedBadge isShared={isShared} />
            </div>
          ) : null}

          <PrivilegeEnforcementWrapper requiredPrivileges={[LIST_WRITER]}>
            <ListCardOptions
              listId={node.id}
              listName={node.name}
              folderId={folderId}
              onFolderDelete={() => onFolderDelete?.()}
              shareObject={shareObject}
              listRefetch={refetch}
              isFavorite={!!node.nodeUserPreferences?.favorite}
              isArchived={node.state === PeopleListState.archived}
              isReady={node.state === PeopleListState.ready}
              displayOptions={listCardDisplayOptions}
              refetchListsAfterUnfavorite={favoriteFilterApplied}
            />
          </PrivilegeEnforcementWrapper>
        </div>
      </div>
    </ListCard>
  );
};
