import React from 'react';
import { classNames } from '@helpers/classNames';
import {
  ExclamationTriangleIcon,
  XCircleIcon,
  XMarkIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  ArrowLongRightIcon
} from '@heroicons/react/20/solid';
import { getSemanticColors } from '@config/semantic-colors';
import { Link } from 'react-router-dom';
import { IntrinsicChildrenProps } from '@src/types';
import { useTranslation } from 'react-i18next';

// Default is error
type AlertType = 'error' | 'warning' | 'success' | 'info';

type AlertAction = {
  onClick: (args: any) => void;
  label: React.ReactNode;
};

interface AlertProps extends IntrinsicChildrenProps {
  actions?: AlertAction[];
  className?: string;
  'data-testid'?: string;
  dismissable?: boolean;
  key?: string | number;
  onDismiss?: (args: any) => void;
  title?: string;
  type?: AlertType;
  link?: {
    text: string;
    href: string;
  };
}

const Icon = ({ type }: { type: AlertType }) => {
  const colors = getSemanticColors(type);

  switch (type) {
    case 'success':
      return (
        <CheckCircleIcon className={classNames(colors.icon, 'tw-h-5 tw-w-5')} aria-hidden="true" />
      );
    case 'info':
      return (
        <InformationCircleIcon
          className={classNames(colors.icon, 'tw-h-5 tw-w-5')}
          aria-hidden="true"
        />
      );
    case 'warning':
      return (
        <ExclamationTriangleIcon
          className={classNames(colors.icon, 'tw-h-5 tw-w-5')}
          aria-hidden="true"
        />
      );
    default:
      return (
        <XCircleIcon className={classNames(colors.icon, 'tw-h-5 tw-w-5')} aria-hidden="true" />
      );
  }
};

export const Alert: React.FC<AlertProps> = ({
  actions,
  children,
  className,
  dismissable,
  link,
  onDismiss,
  title,
  type = 'error',
  ...props
}) => {
  const colors = getSemanticColors(type);
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        colors.background,
        className ? className : '',
        'tw-rounded-md tw-p-4 tw-shadow'
      )}
      role="alert"
      data-testid={props['data-testid']}
    >
      <div className="tw-flex tw-items-center">
        <div className="tw-flex-shrink-0">
          <Icon type={type} />
        </div>
        <div className="tw-ml-3">
          {title ? (
            <h3
              data-testid="alert:title"
              className={classNames(colors.title, 'tw-text-sm tw-font-medium tw-mb-2')}
            >
              {title}
            </h3>
          ) : null}
          <div className={classNames(colors.message, 'tw-text-sm')}>{children}</div>

          {actions ? (
            <div className="tw-mt-4">
              <div className="-tw-mx-2 -tw-my-1.5 tw-flex">
                {actions.map((action, index) => (
                  <button
                    key={`${action.onClick.name}-${index}`}
                    data-testid="alert:button"
                    onClick={action.onClick}
                    type="button"
                    className={classNames(
                      colors.buttonBackground,
                      colors.buttonText,
                      colors.buttonHoverBackground,
                      colors.buttonFocusRing,
                      colors.buttonFocusRingOffset,
                      'tw-rounded-md tw-px-2 tw-py-1.5 tw-text-sm tw-font-medium focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2'
                    )}
                  >
                    {action.label}
                  </button>
                ))}
              </div>
            </div>
          ) : null}
        </div>

        {dismissable ? (
          <div className="tw-ml-auto tw-pl-3">
            <div className="-tw-mx-1.5 -tw-my-1.5">
              <button
                onClick={onDismiss}
                data-testid="alert:dismiss"
                type="button"
                className={classNames(
                  colors.background,
                  colors.link,
                  colors.linkHover,
                  colors.buttonHoverBackground,
                  colors.buttonFocusRing,
                  colors.buttonFocusRingOffset,
                  'tw-inline-flex tw-rounded-md tw-p-1.5 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2'
                )}
              >
                <span className="tw-sr-only">{t('common.dismiss')}</span>
                <XMarkIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        ) : null}

        {link ? (
          <div className="tw-ml-auto tw-pl-3">
            <div className="-tw-mx-1.5 -tw-my-1.5">
              <Link to={link.href} className={classNames(colors.link, colors.linkHover)}>
                {link.text}
                <span aria-hidden="true">
                  <ArrowLongRightIcon />
                </span>
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
